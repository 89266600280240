import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, path, description, image, author }) => {
  const data = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
        title
        url
        description
        keywords
        image {
          asset {
            fixed(width: 1200) {
              src
            }
          }
        }
        author {
          name
        }
      }
    }
  `)

  const titleTag = title || data.site.title
  const descriptionTag = description || data.site.description
  const urlTag = new URL(path || "", data.site.url)
  const imageTag = image || data.site.image.asset.fixed.src
  const authorTag = author || data.site.author.name
  console.log(imageTag)
  return (
    <Helmet>
      <title>{titleTag}</title>
      <link rel="canonical" href={urlTag.href} />
      <meta name="description" content={descriptionTag} />
      <meta name="image" content={imageTag} />

      <meta property="og:url" content={urlTag.href} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={titleTag} />
      <meta property="og:description" content={descriptionTag} />
      <meta property="og:image" content={imageTag} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={authorTag} />
      <meta name="twitter:title" content={titleTag} />
      <meta name="twitter:description" content={descriptionTag} />
      <meta name="twitter:image" content={imageTag} />
    </Helmet>
  )
}

// SEO.defaultProps = {
//   lang: "en",
//   meta: [],
//   keywords: [],
// }

// SEO.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.array,
//   keywords: PropTypes.arrayOf(PropTypes.string),
//   title: PropTypes.string.isRequired,
// }

export default SEO

// const detailsQuery = graphql`
//   query DefaultSEOQuery {
//     site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
//       title
//       description
//       keywords
//       author {
//         name
//       }
//     }
//   }
// `

// function SEO({ description, lang, meta, keywords, title, image }) {
//   return (
//     <StaticQuery
//       query={detailsQuery}
//       render={data => {
//         const metaDescription =
//           description || (data.site && data.site.description) || ""
//         const siteTitle = (data.site && data.site.title) || ""
//         const siteAuthor =
//           (data.site && data.site.author && data.site.author.name) || ""
//         const metaImage = image

//         return (
//           <Helmet
//             htmlAttributes={{ lang }}
//             title={title}
//             titleTemplate={title === siteTitle ? "%s" : `%s | ${siteTitle}`}
//             meta={[
//               {
//                 name: "description",
//                 content: metaDescription,
//               },
//               {
//                 property: "og:title",
//                 content: title,
//               },
//               {
//                 property: "og:description",
//                 content: metaDescription,
//               },
//               {
//                 property: "og:type",
//                 content: "website",
//               },
//               {
//                 property: "og:image",
//                 content: metaImage,
//               },
//               {
//                 name: "twitter:card",
//                 content: "summary",
//               },
//               {
//                 name: "twitter:creator",
//                 content: siteAuthor,
//               },
//               {
//                 name: "twitter:title",
//                 content: title,
//               },
//               {
//                 name: "twitter:image",
//                 content: metaImage,
//               },
//               {
//                 name: "twitter:description",
//                 content: metaDescription,
//               },
//             ]
//               .concat(
//                 keywords && keywords.length > 0
//                   ? {
//                       name: "keywords",
//                       content: keywords.join(", "),
//                     }
//                   : []
//               )
//               .concat(meta)}
//           />
//         )
//       }}
//     />
//   )
// }
