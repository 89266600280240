// import {Link} from 'gatsby'
import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import classNames from "classnames"

const Header = ({ onHideNav, onShowNav, showNav, siteTitle }) => {
  const data = useStaticQuery(graphql`
    query NavQuery {
      sanitySiteSettings {
        mainNavigation {
          page {
            title
          }
          slug {
            current
          }
        }
      }
    }
  `)
  const [open, setOpen] = useState(false)

  const opened = classNames({ hidden: open, block: !open })
  const closed = classNames({ hidden: !open, block: open })

  return (
    <>
      <div className="relative overflow-hidden border-b border-gray-200 bg-white">
        <div className="relative py-6 ">
          <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
            <nav className="relative flex items-center justify-between sm:h-10 md:justify-center">
              <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <Link
                    to="/"
                    className="text-2xl text-gray-700  font-extrabold tracking-wider antialiased no-underline"
                  >
                    {/* <img className="w-8 inline-block" src="https://res.cloudinary.com/danielkapper-com/image/upload/v1587146691/LogoMakr_4vWiC7_ocflro.png" /> */}
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      className="w-8 h-8 inline mr-1 text-indigo-600"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                        clipRule="evenodd"
                      />
                    </svg>
                    {/* <span className="text-indigo-500 ">JAM</span>inary */}
                    Jaminary
                  </Link>

                  <div className="-mr-2 flex items-center md:hidden">
                    {!open ? (
                      <button
                        type="button"
                        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                        onClick={() => setOpen(!open)}
                      >
                        <svg
                          className={classNames("h-6 w-6", opened)}
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h16"
                          />
                        </svg>
                      </button>
                    ) : (
                      <button
                        onClick={() => setOpen(!open)}
                        type="button"
                        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                      >
                        <svg
                          className={classNames("h-6 w-6", closed)}
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                {data.sanitySiteSettings.mainNavigation.map(item => {
                  return (
                    <Link
                      key={item.id}
                      to={item.slug.current}
                      className="ml-10 no-underline font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out"
                    >
                      {item.page.title}
                    </Link>
                  )
                })}
              </div>
              <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
                <span className="inline-flex rounded-full shadow">
                  <Link
                    to="/project-estimate"
                    className="inline-flex items-center no-underline px-4 py-3 border border-transparent text-base leading-6 font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                  >
                    Get started
                  </Link>
                </span>
              </div>
            </nav>
          </div>
        </div>
      </div>
      {open && (
        <div className="relative top-0 inset-x-0 p-2 md:hidden">
          <div className="rounded-lg shadow-md transition transform origin-top-right">
            <div className="rounded-lg bg-white shadow-xs overflow-hidden ">
              <div className="px-2 pt-2 pb-3 z-50">
                {data.sanitySiteSettings.mainNavigation.map(item => {
                  return (
                    <Link
                      key={item.id}
                      to={item.slug.current}
                      className="no-underline block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                    >
                      {item.page.title}
                    </Link>
                  )
                })}
              </div>
              <div>
                <Link
                  to="/project-estimate"
                  className="no-underline block w-full px-5 py-3 text-center font-medium text-indigo-600 hover:bg-gray-100 hover:text-indigo-700 focus:outline-none focus:bg-gray-100 focus:text-indigo-700 transition duration-150 ease-in-out"
                  // className="inline-flex w-full text-center items-center no-underline px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                >
                  Get started
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* </div> */}
    </>
  )
}

export default Header

{
  /* <div className="relative overflow-hidden border-b border-gray-200 bg-white z-50">
      <div className="relative py-6 z-50">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
          <nav className="relative flex items-center justify-between sm:h-10 md:justify-center z-50">
            <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
              <div className="flex items-center justify-between w-full md:w-auto">
                <Link
                  to="/"
                  className="text-2xl text-gray-700  font-extrabold tracking-wider antialiased no-underline"
                >
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    className="w-8 h-8 inline mr-1"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-indigo-500 ">JAM</span>inary
                </Link>
  
                <div className="-mr-2 flex items-center md:hidden">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                  >
                    <svg
                      className="h-6 w-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="hidden md:block">
              {data.sanitySiteSettings.mainNavigation.map(item => {
                return (
                  <Link
                    key={item.id}
                    to={item.slug.current}
                    className="ml-10 no-underline font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out"
                  >
                    {item.page.title}
                  </Link>
                )
              })}
            </div>
            <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
              <span className="inline-flex rounded-full shadow">
                <Link
                  to="/project-estimate"
                  className="inline-flex items-center no-underline px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                >
                  Get started
                </Link>
              </span>
            </div>
          </nav>
        </div>
         {open && (
        <div
          className="absolute top-0 inset-x-0 p-2 md:hidden z-50"
        >
          <div
            className="rounded-lg shadow-md transition transform origin-top-right"
          >
            <div className="rounded-lg bg-white shadow-xs overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  <Link
                    to="/"
                    className="text-2xl text-gray-700  font-extrabold tracking-wider antialiased no-underline"
                  >
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      className="w-8 h-8 inline mr-1"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-indigo-500 ">JAM</span>inary
                  </Link>
                </div>
                <div className="-mr-2">
                  {/* <button @click="open = false" type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"> */
}
//             <button
//               onClick={() => setOpen(!open)}
//               type="button"
//               className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
//             >
//               <svg
//                 // className="h-6 w-6"
//                 className={classNames("h-6 w-6", closed)}
//                 stroke="currentColor"
//                 fill="none"
//                 viewBox="0 0 24 24"
//               >
//                 <path
//                   stroke-linecap="round"
//                   stroke-linejoin="round"
//                   stroke-width="2"
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             </button>
//           </div>
//         </div>
//         <div className="px-2 pt-2 pb-3">
//           {data.sanitySiteSettings.mainNavigation.map(item => {
//             return (
//               <Link
//                 key={item.id}
//                 to={item.slug.current}
//                 className="no-underline block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
//               >
//                 {item.page.title}
//               </Link>
//             )
//           })}
//         </div>
//         <div>
//           <Link
//             to="/project-estimate"
//             className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100 hover:text-indigo-700 focus:outline-none focus:bg-gray-100 focus:text-indigo-700 transition duration-150 ease-in-out"
//           >
//             Get started
//           </Link>
//         </div>
//       </div>
//     </div>
//   </div>
// )}
// </div>
// </> */
// }
