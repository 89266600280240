import React from "react"
// import Header from './header'
import Nav from "../components/global/Nav"
import Footer from "../global/footer"
import "../styles/layout.css"

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle }) => (
  <>
    {/* <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} /> */}
    <Nav
      siteTitle={siteTitle}
      onHideNav={onHideNav}
      onShowNav={onShowNav}
      showNav={showNav}
    />
    <div>{children}</div>
    <Footer />
  </>
)

export default Layout
